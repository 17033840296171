import React from 'react'

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Client:</span>
                        <p>Top Car Rentals</p>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Locație:</span>
                        Cluj-Napoca, România
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Tehnologii:</span>
                        Java, Spring Boot, JPA, PostgreSQL, AWS RDS, AWS EC2, AWS Route53, React, Bootstrap, Nginx
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Finalizat:</span>
                        Ianuarie 2023
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-calendar'></i>
                        </div>
                        <span>Durată contract:</span>
                        <p>36 luni</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CaseStudiesSidebar