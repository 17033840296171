import React from 'react'
import CaseStudiesSidebar from './CaseStudiesSidebar'
import details1 from '../../../assets/images/projects/projects-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about"/>
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Dezvoltare de produs</span>
                            <h3>Compania</h3>
                            <p>Top Car Rentals era în urmă cu mai bine de zece ani o firmă care deținea un parc de
                                închirieri de mașini pe teritoriul României. Pentru că piața concurențială era în
                                continuă expansiune, s-au impus mai multe modificări în strategia de business, care au
                                implicat, inevitabil, soluțiile digitale moderne și eficiente. A fost momentul în care
                                am fost contactați de reprezentații firmei care ne-au expus ideile și solicitările lor
                                cărora noi trebuia să le găsim cea mai bună rezolvare digitală.</p>
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="image">
                                        <img src={project2} alt="about"/>
                                    </div>
                                </div>
                                <h3>Provocarea</h3>
                                <p>Așa cum obișnuim să lucrăm cu toți clienții noștri, am stat îndelung la discuții și
                                    am făcut o analiză riguroasă a business-ului partenerului nostru, corelată cu
                                    dorințele sale de dezvoltare. Am ajuns la concluzia că este nevoie să creăm o
                                    platformă digitală specializată, care să reunească mai mulți furnizori de servicii
                                    de închiriere auto din țară. Am studiat concurența locală și din alte țări și am
                                    trasat liniile importante ale structurii platformei pe care am creat-o de la 0, de
                                    la concept la soluție tehnică și vizuală.</p>
                                <div className="col-lg-12 col-md-12">
                                    <div className="content">
                                        <h3>Soluția</h3>
                                        <p>În concluzie, a trebuit să construim un sistem care să aibă 3 componente:</p>
                                        <ul>
                                            <li>Un API REST care gestionează toată logica si securitatea datelor;</li>
                                            <li>Un site de administrare pentru gestionarea partenerilor, mașinilor,
                                                promoțiilor, rezervărilor și facturilor;
                                            </li>
                                            <li>Un site web de tip front office, optimizat SEO, folosit pentru
                                                prezentarea și promovarea serviciilor de închiriere de mașini
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p>Proiectul s-a întins pe parcursul a trei ani, pentru că sistemul pe care l-am creat s-a
                                dovedit a fi foarte robust și rapid în funcționare, astfel că business-ul partenerului
                                nostru s-a derulat foarte bine, iar lucrurile s-au dezvoltat din mers, necesitând mereu
                                noi update-uri și alte replici ale platformei.</p>
                            <p>Pentru crearea platformei românești <a
                                href="https://www.topcarrentals.ro">www.topcarrentals.ro</a> am lucrat în paralel cu
                                două echipe de profesioniști. Una formată din patru specialiști în tehnologii web, HTML,
                                CSS, Javascript, care și-au pus în aplicare toate abilitățile pentru a rezolva exemplar
                                partea de front-end. Au transpus în realitate un design foarte atractiv potențat de tot
                                ceea ce înseamnă partea de programare, SEO etc.</p>
                            <p>O a doua echipă formată din trei ingineri specialiști și-au pus mințile ingenioase la
                                contribuție și au realizat toată baza de date, au creat logica de funcționare a
                                platformei, care trebuia să răspundă mai multor tipuri de căutări: multiple categorii de
                                mașini - mai accesibile ca preț, de lux, microbuze pentru grupuri - în zeci de orașe din
                                țară, furnizate de mai multe companii. Tot ei s-au ocupat de partea de infrastructură și
                                securitatea informațiilor.</p>
                            <p>Soluția finală pe care am oferit-o partenerului nostru are la bază următoarele
                                tehnologii: Java 11, Spring Boot, JPA, PostgreSQL, AWS RDS, AWS EC2, AWS Route53, React,
                                Bootstrap, Nginx.</p>


                            <h3>Continuarea</h3>
                            <p>Am lansat mai întâi portalul <a
                                href="https://www.topcarrentals.ro">www.topcarrentals.ro</a> cu servicii de închiriere
                                auto exclusiv
                                în România. După doi ani de la lansare, partenerul nostru și-a extins serviciile în
                                Dubai, de aceea ne-a solicitat să adaptăm platforma inițială la toate ofertele sale de
                                acolo. Așa a apărut <a href="https://www.toprentdubai.com">www.toprentdubai.com</a>, sub
                                genericul ”All You Need to Rent in Dubai”.
                                Practic, platforma creată inițial de Vertical Digital a avut nevoie de un design nou,
                                dar și de mai multe actualizări, fiind vorba de închirieri de mașini, dar și de iahturi
                                și apartamente.</p>
                            <h3>Viitorul</h3>
                            <p>Pentru că planurile partenerului nostru s-au dezvoltat conform așteptărilor sale și
                                colaborarea cu Vertical Digital s-a dovedit a fi eficientă, următorul proiect la care
                                vom lucra împreună este unul destinat închirierii de mașini, jet-uri și avioane pe
                                Riviera Franceză și care va fi lansat, cel mai probabil, anul viitor.</p>

                            <h3>Opinii</h3>
                            <p>”Ne-am bucurat de la bun început pentru încrederea pe care partenerul nostru ne-a
                                arătat-o, alegând Vertical Digital să pună în aplicarea acest proiect. Ne-am pus toată
                                priceperea și pasiunea pentru a oferi servicii de bună calitate și o bază digitală
                                solidă pentru a-și crește business-ul. Confirmarea a venit din faptul că partenerul
                                nostru a revenit permanent cu proiecte noi în afara țării. Suntem onorați că ne-a ales
                                tot pe noi pentru continuarea proiectelor și abia așteptăm să le ducem la bun sfârșit și
                                să primim noi provocări.” - <strong>Dan Pop - CEO Vertical Digital</strong></p>
                            <p>“Colaborarea noastră cu Vertical Digital a fost una extrem de productivă. Am găsit în
                                echipa lor un partener profesionist, serios și punctual, care a înțeles nevoile noastre
                                și a dezvoltat o platformă web care a depășit așteptările mele. Îi recomand cu încredere
                                tuturor celor care doresc să lucreze cu o echipă de specialiști în domeniul
                                digital." - <strong>Dan Morar - CEO Top Car Rentals</strong></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaseStudiesDetailsContent